import Vue from "vue";
import Vuex from "vuex";
import { createLogger } from "vuex";
import VuexPersistence from "vuex-persist";
import SecureLS from "secure-ls";

import types from "./modules/types";
import auth from "./modules/auth";
import roles from "./modules/roles";
import ui from "./modules/ui";
import stores from "./modules/stores";
import cart from "./modules/cart";
import orders from "./modules/orders";
import crud from "./modules/crud";
import users from "./modules/users";
import files from "./modules/files";
import socket from "./modules/socket";
import chat from "./modules/chat";

// const debug = process.env.NODE_ENV !== "production";

Vue.use(Vuex);

const modules = {
  types,
  ui,
  auth,
  roles,
  users,
  cart,
  orders,
  files,
  socket,
  chat,

  // entities modules
  regions: crud("Region", "units/regions"),
  stores,
  sections: crud("Section", "units/sections", "storeId"),
  categories: crud("Category", "units/categories", "sectionId"),
  products: crud("StoreProduct", "products", "categoryId"),
  favorites: crud("Favorite", "products/favorites"),
  addresses: crud("Address", "addresses"),
};

const pData = new VuexPersistence({
  key: "pData",
  storage: window.localStorage,
  reducer: (state) => {
    return {
      types: { types: state.types.types },
      cart: { items: state.cart.items, inProgressLock: state.cart.inProgressLock },
      ui: {
        language: state.ui.language,
        menuBadges: state.ui.menuBadges,
        isMobile: state.ui.isMobile,
      },
      users: {
        dispatchedDrivers: state.users.dispatchedDrivers,
        shareLocation: state.users.shareLocation,
      },

      // entities data
      regions: { ...state.regions },
      stores: { ...state.stores },
      sections: { ...state.sections },
      categories: { ...state.categories },
      products: { ...state.products },
      favorites: { ...state.favorites },
      chat: { ...state.chat },
    };
  },
}).plugin;

const ls = new SecureLS({ isCompression: false });
const spData = new VuexPersistence({
  key: "spData",
  storage: window.localStorage,
  reducer: (state) => {
    return {
      auth: {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
      },
      roles: {
        roles: state.roles.roles,
      },
      users: {
        driversOnDuty: state.users.driversOnDuty,
      },
    }
  },
}).plugin;

const store = new Vuex.Store({
  modules,
  // plugins: debug ? [createLogger(), pData] : [pData],
  plugins: [pData, spData],
});

export default store;
