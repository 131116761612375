import Vue from "vue";
import Router from "vue-router";
import { authorizationService } from "@/services";

// Layouts
const GenericLayout = () => import("@/common/layouts/GenericLayout");
const AppLayout = () => import("@/common/layouts/AppLayout");
const DefaultLayout = () => import("@/common/layouts/DefaultLayout");

// Views
const About = () => import("@/views/about/About");
const Intro = () => import("@/views/about/Intro");

const Stores = () => import("@/views/stores/Stores");
const StoresSearch = () => import("@/views/stores/StoresSearch");
const OpenStore = () => import("@/views/stores/OpenStore");
const StoreSearch = () => import("@/views/stores/StoreSearch");
const OpenSection = () => import("@/views/stores/OpenSection");
const OpenProduct = () => import("@/views/stores/OpenProduct");
const Cart = () => import("@/views/orders/Cart");
const Checkout = () => import("@/views/orders/Checkout");
const Favorites = () => import("@/views/stores/Favorites");

const Orders = () => import("@/views/orders/Orders");
const Order = () => import("@/views/orders/Order");

const Offers = () => import("@/views/offers/Offers");
const Settings = () => import("@/views/settings/Settings");
const Profile = () => import("@/views/settings/Profile");
const BusinessSettings = () => import("@/views/settings/BusinessSettings");
const ManagedStores = () => import("@/views/settings/ManagedStores");
const AdminStoreProfile = () => import("@/views/admin/units/AdminStoreProfile");
const StoreProfile = () => import("@/views/settings/StoreProfile");
const ProductsManager = () => import("@/views/admin/products/ProductsManager");

const SavedAddresses = () => import("@/views/settings/SavedAddresses");
const Address = () => import("@/views/settings/Address");
const ChangeEmail = () => import("@/views/settings/ChangeEmail");
const ChangePhone = () => import("@/views/settings/ChangePhone");
const ChangePassword = () => import("@/views/settings/ChangePassword");

const SelectRegions = () => import("@/views/settings/Regions");
const AdminRegions = () => import("@/views/admin/units/AdminRegions");
const RegionStores = () => import("@/views/admin/units/RegionStores");
const RegionEditor = () => import("@/views/admin/units/RegionEditor");
const CustomerRegion = () => import("@/views/admin/customers/CustomerRegion");
const StoreSections = () => import("@/views/admin/units/StoreSections");
const SectionEditor = () => import("@/views/admin/units/SectionEditor");
const SectionCategories = () => import("@/views/admin/units/SectionCategories");
const CategoryEditor = () => import("@/views/admin/units/CategoryEditor");

// const StoreEditor = () => import("@/views/admin/units/StoreEditor");

const Dashboards = () => import("@/views/admin/dashboards/Dashboards");
const DataTypes = () => import("@/views/admin/data.types/DataTypes");
const NotificationsOptions = () =>
  import("@/views/admin/notifications/NotificationsOptions");
const OffersManager = () => import("@/views/admin/offers/OffersManager");
const OrdersManager = () => import("@/views/admin/orders/OrdersManager");
const OrderDetails = () => import("@/views/orders/OrderDetails");
const ProductEditor = () => import("@/views/admin/products/ProductEditor");

// Drivers
const ActiveDelivery = () => import("@/views/delivery/ActiveDelivery");

// View Access Control
const Users = () => import("@/views/admin/ac/Users");
const UserEditor = () => import("@/views/admin/ac/UserEditor");
const RoleEditor = () => import("@/views/admin/ac/RoleEditor");
const Roles = () => import("@/views/admin/ac/Roles");
const Types = () => import("@/views/settings/Types");
const TypeEditor = () => import("@/views/settings/TypeEditor");

// general
// const MapLocation = import("@/views/general/MapLocation");

// Views - Errors
const Page404 = () => import("@/views/errors/Page404");
const Page500 = () => import("@/views/errors/Page500");

// Views - Authentication
const Login = () => import("@/views/auth/Login");
const PinLogin = () => import("@/views/auth/PinLogin");
// const Register = () => import("@/views/auth/Register");
const PhoneRegister = () => import("@/views/auth/PhoneRegister");
const ForgotPassword = () => import("@/views/auth/ForgotPassword");
const PasswordReset = () => import("@/views/auth/PasswordReset");
const ResetPassword = () => import("@/views/auth/ResetPassword");
const EmailConfirmation = () => import("@/views/auth/EmailConfirmation");
const PinConfirmation = () => import("@/views/auth/PinConfirmation");

// const appType = process.env.VUE_APP_TYPE ? process.env.VUE_APP_TYPE.toLowerCase() : null;
// const HOME_PAGE = appType === "driver" ? "/delivery/active" :
// appType === "store-manager" ? "/business/stores/dispatchers" :
// "/stores";

const HOME_PAGE = authorizationService.hasRole("DRIVER") ?
  "/delivery/active" :
  authorizationService.hasRole("DRIVERSTORE-MANAGER") ?
  "/business/stores/dispatchers":
  "/stores";

function configRoutes() {
  return [
    {
      path: "/",
      redirect: HOME_PAGE,
      name: "default",
      component: AppLayout,
      children: [
        {
          path: "stores",
          name: "stores",
          component: Stores,
          meta: {
            breadcrumbs: [{ name: "app.route.stores" }],
            resource: "stores",
          },
        },
        {
          path: "store/:storeId/:store",
          name: "store",
          component: OpenStore,
          meta: {
            breadcrumbs: [
              { name: "app.route.stores", link: "/#/stores" },
              { name: "app.route.store", dynamic: true },
            ],
            resource: "store",
          },
        },
        {
          path: "store/:storeId/:store/section/:id/:section",
          name: "section",
          component: OpenSection,
          meta: {
            breadcrumbs: [
              { name: "app.route.stores", link: "/#/stores" },
              {
                name: "app.route.store", link: "/#/store/:storeId/:store",
                nameParam: "store",
                dynamic: true
              },
              {
                name: "app.route.section",
                nameParam: "section",
                disabled: true,
                dynamic: true,
              },
            ],
          },
        },
        {
          path:
            "product/:storeId/:sectionId/:section/:categoryId/:category/:id",
          name: "product",
          component: OpenProduct,
          meta: {
            breadcrumbs: [
              { name: "app.route.stores", link: "/#/stores" },
              { name: "app.route.store", link: "/#/store/:storeId", dynamic: true },
              { name: "app.route.section", link: "/#/section/:storeId/:sectionId/:section", dynamic: true },
              { name: "app.route.category", link: "/#/section/:storeId/:sectionId/:section/#cat-:categoryId", dynamic: true },
              {
                name: "app.route.product",
                dynamic: true,
                disabled: true,
                skipTranslate: true,
              },
            ],
            back: {
              name: "section",
              params: { sectionId: "sectionId", section: "section" },
            },
          },
        },
        {
          path: "search/:storeId",
          name: "products.search",
          component: StoreSearch,
          meta: {
            resource: "stores",
            back: {
              name: "store",
              params: { storeId: "storeId" },
            },
          },
        },
        {
          path: "stores/search",
          name: "stores.search",
          component: StoresSearch,
        },
        {
          path: "cart/:storeId",
          name: "cart",
          component: Cart,
          meta: {
            breadcrumbs: [{ name: "app.route.cart", disabled: true }],
            resource: "cart",
          },
        },
        {
          path: "checkout/:storeId",
          name: "checkout",
          component: Checkout,
          meta: {
            breadcrumbs: [
              { name: "app.route.cart", link: "/#/cart/:storeId" },
              { name: "app.route.checkout", disabled: true }],
            resource: "cart",
          },
        },
        {
          path: "orders",
          name: "orders",
          component: Orders,
          meta: {
            breadcrumbs: [{ name: "app.route.orders", disabled: true }],
            resource: "orders",
          },
        },
        {
          path: "order/:uuid",
          name: "order",
          component: Order,
          props: {
            customer: true,
            support: true,
            // history: true,
          },
          meta: {
            breadcrumbs: [{ name: "app.route.orders", disabled: true }],
            resource: "order",
          },
        },
        {
          path: "favorites/:storeId",
          name: "favorites",
          component: Favorites,
          meta: {
            breadcrumbs: [{ name: "app.route.favorites", disabled: true }],
            resource: "favorites",
          },
        },
        {
          path: "offers",
          name: "offers",
          component: Offers,
          meta: {
            breadcrumbs: [
              { name: "app.route.shopping", link: "/#/store" },
              { name: "app.route.offers", disabled: true },
            ],
            resource: "offers",
          },
        },

        //public
        // {
        //   path: "map-location",
        //   name: "map.location",
        //   component: MapLocation,
        // },
        {
          path: "intro",
          name: "intro",
          component: Intro,
          meta: {
            breadcrumbs: [{ name: "app.route.intro" }],
          },
        },
        {
          path: "about",
          name: "about",
          component: About,
          meta: {
            breadcrumbs: [{ name: "app.route.about" }],
          },
        },

        //settings
        {
          path: "/settings",
          redirect: "/settings/menu",
          component: GenericLayout,
          children: [
            {
              path: "menu",
              name: "settings",
              component: Settings,
              meta: {
                breadcrumbs: [{ name: "app.route.settings", disabled: true }],
                resource: "settings",
                back: { name: "stores" },
              },
            },
            {
              path: "profile",
              name: "profile",
              component: Profile,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.profile", disabled: true },
                ],
                resource: "profile",
                back: { name: "settings" },
              },
            },
            {
              path: "select-region",
              name: "select-region",
              component: SelectRegions,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.regions", disabled: true },
                ],
                resource: "settings",
                back: { name: "settings" },
              },
            },
            {
              path: "addresses",
              name: "addresses",
              component: SavedAddresses,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.savedAddresses", disabled: true },
                ],
                resource: "settings",
                back: { name: "settings" },
              },
            },
            {
              path: "address/:id",
              name: "address",
              component: Address,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.address", disabled: true },
                ],
                resource: "settings",
                back: { name: "addresses" },
              },
            },
            {
              path: "change-email",
              name: "change-email",
              component: ChangeEmail,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.changeEmail", disabled: true },
                ],
                resource: "settings",
                back: { name: "settings" },
              },
            },
            {
              path: "change-phone",
              name: "change-phone",
              component: ChangePhone,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.changePhone", disabled: true },
                ],
                resource: "settings",
                back: { name: "settings" },
              },
            },
            {
              path: "change-password",
              name: "change-password",
              component: ChangePassword,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "app.route.changePassword", disabled: true },
                ],
                resource: "settings",
                back: { name: "settings" },
              },
            },
          ],
        },

        //business
        {
          path: "/business",
          redirect: "/business/stores/profiles",
          component: GenericLayout,
          children: [
            {
              path: "stores/profiles",
              name: "managed.stores.profiles",
              component: ManagedStores,
              props: { target: "store.profile" },
              meta: {
                breadcrumbs: [
                  { name: "app.route.business", disabled: true },
                  { name: "app.route.storeProfiles", disabled: true },
                ],
                resource: "managed.stores.profiles",
                back: { name: "stores" },
              },
            },
            {
              path: "store/:storeId/profile",
              name: "store.profile",
              component: StoreProfile,
              meta: {
                breadcrumbs: [
                  { name: "app.route.business", disabled: true },
                  { name: "app.route.storeProfile", disabled: true },
                ],
                resource: "managed.store.profile",
                back: { name: "managed.stores.profiles" },
              },
            },
            {
              path: "stores/products",
              name: "managed.stores.products",
              component: ManagedStores,
              props: { target: "managed.store.products" },
              meta: {
                breadcrumbs: [
                  { name: "app.route.business", disabled: true },
                  { name: "app.route.managedStores", disabled: true },
                ],
                resource: "managed.stores.products",
                back: { name: "stores" },
              },
            },
            {
              path: "store/:storeId/products",
              name: "managed.store.products",
              component: ProductsManager,
              props: { target: "managed.store.product" },
              meta: {
                breadcrumbs: [
                  { name: "app.route.business", disabled: true },
                  { name: "app.route.products", disabled: true },
                ],
                resource: "managed.stores.products",
                back: { name: "managed.stores.products" },
              },
            },
            {
              path: "store/:storeId/product/:id",
              name: "managed.store.product",
              component: ProductEditor,
              meta: {
                breadcrumbs: [
                  { name: "app.route.business", disabled: true },
                  { name: "app.route.products", disabled: true },
                  { name: "general.new", disabled: true, dynamic: true },
                ],
                resource: "managed.store.product",
                back: { name: "managed.store.products" },
              },
            },
            {
              path: "stores/dispatchers",
              name: "managed.stores.dispatchers",
              component: ManagedStores,
              props: { target: "managed.store.dispatcher" },
              meta: {
                breadcrumbs: [
                  { name: "app.route.business", disabled: true },
                  { name: "app.route.managedStores", disabled: true },
                ],
                resource: "managed.stores.dispatchers",
                back: { name: "stores" },
              },
            },
            {
              path: "store/:storeId/dispatcher",
              name: "managed.store.dispatcher",
              component: OrdersManager,
              meta: {
                breadcrumbs: [
                  { name: "app.route.business", disabled: true },
                  { name: "app.route.storeDispatcher", disabled: true },
                ],
                resource: "managed.store.dispatcher",
              },
            },
          ],
        },

        // admin
        {
          path: "/admin",
          redirect: "/admin/dashboard",
          component: GenericLayout,
          children: [
            {
              path: "dashboards",
              name: "dashboards",
              component: Dashboards,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.dashboard", disabled: true },
                ],
                resource: "dashboard",
              },
            },
            {
              path: "regions",
              name: "regions",
              component: AdminRegions,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.regions", disabled: true },
                ],
                resource: "regions",
              },
            },
            {
              path: "region/:regionId",
              name: "region.editor",
              component: RegionEditor,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.regions" },
                  { name: "general.new", disabled: true, dynamic: true },
                ],
                resource: "region.editor",
              },
            },
            {
              path: "region/:regionId/stores",
              name: "region.stores",
              component: RegionStores,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.regions", link: "/#/admin/regions" },
                  { name: "app.route.stores", disabled: true },
                ],
                resource: "stores.manager",
                back: { name: "regions", params: { regionId: "regionId" } },
              },
            },
            {
              path: "region/:regionId/store/:storeId",
              name: "store.editor",
              component: AdminStoreProfile,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "app.route.stores", disabled: true },
                  { name: "general.new", disabled: true, dynamic: true },
                ],
                resource: "store.editor",
                back: { name: "region.stores", params: { regionId: "regionId" } },
              },
            },
            {
              path: "region/:regionId/store/:storeId/sections",
              name: "admin.store.sections",
              component: StoreSections,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.regions", link: "/#/admin/regions" },
                  { name: "app.route.stores", link: "/#/admin/regions/:regionId/stores" },
                  { name: "app.route.sections", disabled: true },
                ],
                resource: "stores.manager",
                back: { name: "region.stores", params: { regionId: "regionId" } },
              },
            },
            {
              path: "region/:regionId/store/:storeId/section/:sectionId",
              name: "admin.store.section",
              component: SectionEditor,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.regions", link: "/#/admin/regions" },
                  { name: "app.route.stores", link: "/#/admin/regions/:regionId/stores" },
                  { name: "app.route.sections", link: "/#/admin/regions/:regionId/stores/:storeId" },
                  { name: "app.route.section", disabled: true },
                ],
                resource: "stores.manager",
                back: { name: "admin.store.sections", params: { regionId: "regionId", storeId: "storeId" } },
              },
            },
            {
              path: "region/:regionId/store/:storeId/section/:sectionId/categories",
              name: "admin.store.categories",
              component: SectionCategories,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.regions", link: "/#/admin/regions" },
                  { name: "app.route.stores", link: "/#/admin/regions/:regionId/stores" },
                  { name: "app.route.sections", link: "/#/admin/regions/:regionId/stores/:storeId" },
                  { name: "app.route.categories", disabled: true },
                ],
                resource: "stores.manager",
                back: { name: "admin.store.sections", params: { regionId: "regionId", storeId: "storeId" } },
              },
            },
            {
              path: "region/:regionId/store/:storeId/section/:sectionId/category/:categoryId",
              name: "admin.store.category",
              component: CategoryEditor,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.regions", link: "/#/admin/regions" },
                  { name: "app.route.stores", link: "/#/admin/regions/:regionId/stores" },
                  { name: "app.route.sections", link: "/#/admin/regions/:regionId/stores/:storeId" },
                  { name: "app.route.categories", link: "/#/admin/regions/:regionId/stores/:storeId/secion/:sectionId" },
                  { name: "app.route.category", disabled: true },
                ],
                resource: "stores.manager",
                back: { name: "admin.store.sections", params: { regionId: "regionId", storeId: "storeId" } },
              },
            },
            {
              path: "customers",
              name: "customers",
              component: Users,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.management" },
                  { name: "admin.route.customers", disabled: true },
                ],
                resource: "customers",
              },
              props: {
                type: ["CUSTOMER", "TESTER"],
                editRoute: "customer",
              },
            },
            {
              path: "customer/:id",
              name: "customer",
              component: UserEditor,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.management" },
                  { name: "admin.route.customers" },
                  { name: "general.new", disabled: true, dynamic: true },
                ],
                resource: "customer.editor",
                back: { name: "customers" },
              },
            },
            {
              path: "customer/:id/region",
              name: "customer-region",
              component: CustomerRegion,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.customers", link: "/#/admin/customers" },
                  { name: "app.route.regions", disabled: true },
                ],
                resource: "customers",
                back: { name: "customers" },
              },
            },
            {
              path: "store/:storeId/products",
              name: "admin.store.products",
              component: ProductsManager,
              props: { target: "admin.store.product" },
              meta: {
                breadcrumbs: [
                  { name: "admin.route.management" },
                  { name: "admin.route.products", disabled: true },
                ],
                resource: "admin.store.products",
              },
            },
            {
              path: "store/:storeId/product/:id",
              name: "admin.store.product",
              component: ProductEditor,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.management" },
                  { name: "admin.route.products", disabled: true },
                  { name: "general.new", disabled: true, dynamic: true },
                ],
                resource: "product.editor",
                back: { name: "admin.store.products" },
              },
            },
            {
              path: "order/:id",
              name: "order.details",
              component: OrderDetails,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.orders", disabled: true },
                  { name: "general.details", disabled: true, dynamic: true },
                ],
                resource: "order.details",
              },
            },
            {
              path: "offers",
              name: "offers.manager",
              component: OffersManager,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.offers", disabled: true },
                ],
                resource: "offers.manager",
              },
            },
            {
              path: "notifications",
              name: "notifications.options",
              component: NotificationsOptions,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.notifications", disabled: true },
                ],
                resource: "notifications.options",
              },
            },
            {
              path: "users",
              name: "users",
              component: Users,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.users", disabled: true },
                ],
                resource: "users",
              },
              props: {
                type: [
                  "SUPER-ADMIN",
                  "ADMIN",
                  "USER",
                  "MERCHANT",
                  "DRIVER",
                  "ORDER-HANDLER",
                  "TESTER",
                ],
                editRoute: "user",
              },
            },
            {
              path: "user/:id",
              name: "user",
              component: UserEditor,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.users" },
                  { name: "general.new", disabled: true, dynamic: true },
                ],
                resource: "user.editor",
                back: { name: "users" },
              },
            },
            {
              path: "user/:id/roles",
              name: "user.roles",
              component: UserEditor,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.users" },
                  { name: "route.roles", disabled: true },
                  { name: "general.new", disabled: true, dynamic: true },
                ],
                // ac resource name
                resource: "user.roles",
                // roles: [ROLE_USER, ROLE_ADMIN, ROLE_SUPERADMIN]
              },
            },
            {
              path: "user/:id/stores",
              name: "user.stores",
              component: UserEditor,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.users" },
                  { name: "route.stores", disabled: true },
                  { name: "general.new", disabled: true, dynamic: true },
                ],
                // ac resource name
                resource: "stores.manager",
                // roles: [ROLE_USER, ROLE_ADMIN, ROLE_SUPERADMIN]
              },
            },
            {
              path: "roles",
              name: "roles",
              component: Roles,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.roles", disabled: true },
                ],
                resource: "roles",
              },
            },
            {
              path: "role/:id",
              name: "role",
              component: RoleEditor,
              meta: {
                breadcrumbs: [
                  { name: "admin.route.admin" },
                  { name: "admin.route.roles" },
                  { name: "general.new", disabled: true, dynamic: true },
                ],
                resource: "role.editor",
              },
            },
            {
              path: "types",
              name: "data.types",
              component: Types,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "admin.route.dataTypes", disabled: true },
                  {
                    dynamic: true,
                    skipTranslate: false,
                  },
                ],
                resource: "data.types",
              },
            },
            {
              path: "types/:route",
              name: "data.type",
              component: TypeEditor,
              meta: {
                breadcrumbs: [
                  { name: "app.route.settings" },
                  { name: "admin.route.dataTypes", disabled: true },
                ],
                resource: "data.types:{route}",
              },
            },
          ],
        },

        //delivery
        {
          path: "/delivery",
          name: "delivery",
          redirect: "/delivery/active",
          component: GenericLayout,
          children: [
            {
              path: "active",
              name: "delivery.active",
              component: ActiveDelivery,
              meta: {
                breadcrumbs: [
                  { name: "driver.route.activeDelivery", disabled: true },
                ],
                resource: "delivery.active",
              },
            },
            {
              path: ":uuid",
              name: "delivery.order",
              component: Order,
              props: {
                customer: true,
                support: true,
                // history: true,
              },
              meta: {
                breadcrumbs: [{ name: "app.route.orders", disabled: true }],
                resource: "delivery.order",
              },
            },
          ],
        },
      ],
    },
    {
      path: "/auth",
      redirect: "/auth/login",
      component: DefaultLayout,
      children: [
        {
          path: "login",
          name: "login",
          component: PinLogin,
        },
        {
          path: "alt-login",
          name: "alt-login",
          component: Login,
        },
        {
          path: "register",
          name: "register",
          component: PhoneRegister,
        },
        {
          path: "email/:code?",
          name: "email",
          component: EmailConfirmation,
        },
        {
          path: "pin/:code?",
          name: "pin",
          component: PinConfirmation,
        },
        {
          path: "forgot-password",
          name: "forgot-password",
          component: ForgotPassword,
        },
        {
          path: "password-reset",
          name: "password-reset",
          component: PasswordReset,
        },
        {
          path: "reset-password/:code?",
          name: "reset-password",
          component: ResetPassword,
        },
      ],
    },
    {
      path: "/error",
      redirect: "/error/404",
      name: "error",
      component: GenericLayout,
      children: [
        {
          path: "404",
          name: "Error404",
          component: Page404,
        },
        {
          path: "500",
          name: "Error500",
          component: Page500,
        },
      ],
    },
  ];
}

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

Vue.use(Router);
