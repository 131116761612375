import OrdersMixin from "@/views/orders/orders.mixin";
import util from "@/common/helpers/util";
import i18n from "@/locales";
import Push from "push.js";
import { CONNECT } from "@/store/actions/type.socket";
import { REFRESH_PRODUCTS } from "@/store/actions/type.categories";
import { ROOM_DELIVERY } from "@/store/actions/type.chat";
import {
  LOAD_MY_ORDERS,
  LOAD_ACTIVE_ORDERS,
  LOAD_DRIVER_ACTIVE_ORDERS
} from "@/store/actions/type.orders";
import {
  LOAD_CHAT_ROOM,
} from "@/store/actions/type.chat";
import {
  PUBLISH_LOCATION,
  UNPUBLISH_LOCATION
} from "@/store/actions/type.users";

export default {
  install(Vue, { store, router }) {
    try {
      Push.Permission.request();
    } catch {
      console.log("No notification support");
    }

    const socketConnectFunc = () => {
      const { user } = store.state.auth;
      const userType = Vue.hasRole("SUPER-ADMIN") || Vue.hasRole("ADMIN")
        ? "admin"
        : Vue.hasRole("MERCHANT")
          ? "merchant"
          : Vue.hasRole("STORE-MANAGER")
            ? "manager"
            : Vue.hasRole("DRIVER")
              ? "driver"
              : Vue.hasRole("ORDER-HANDLER")
                ? "handler"
                : Vue.hasRole("SUPPORT")
                  ? "support" : "customer";

      // const userType = Vue.hasRole("DRIVER")
      // TODO: add a type for the application instance ADMIN, STORE_MANAGER, etc.
      // instead of using userType. Users can have multiple access roles and
      // multiple installation types

      const goTo = (name, id) => {
        router.push({
          name,
          query: { highlight: id },
        });
      };

      store.subscribe((mutation, state) => {
        if (mutation.type.indexOf("socket/") !== -1) {
          if (mutation.type === "socket/message") {
            const payload = mutation.payload;
            console.log("notification:", payload);

            if (payload.beep) {
              const newOrderAudio = new Audio("aud/beep.wav");
              newOrderAudio.play();
            }

            // TODO: separate notification ui from snackbar ui later
            if (payload.resourceType === "track") {
              const messageKey = `order.update.${userType}.${util.camelize(payload.content)}`;
              console.log("notification key locale:", messageKey);
              console.log("localized notification:", i18n.t(messageKey));
              if (payload.type === "Notification") {
                store.dispatch("ui/showFeedback", {
                  show: true,
                  title: payload.title,
                  text: i18n.t(messageKey),
                  icon: "mdi-cart",
                  timeout: 7000,
                  color: `${OrdersMixin.methods.getStatusTypeColor(payload.content)}`,
                  light: !OrdersMixin.methods.isDarkStatusTheme(payload.content),
                  alert: true,
                  skipTranslation: true,
                  link: `/#/${payload.resource}?highlight=${payload.id}`,
                  actionText: this.gotoText,
                  action: () => goTo(payload.resourceType, payload.resourceId),
                }, { root: true });

                if (!document.hasFocus()) {
                  Push.create(payload.title || "", {
                    body: i18n.t(messageKey),
                    icon: "~/logo.svg",
                    // requireInteraction: true,
                    timeout: 10000,
                    vibrate: [300, 200, 100],
                    onClosed: function () {
                      goTo(payload.resourceType, payload.resourceId);
                      window.focus();
                    },
                  });
                }
              }
            }

            // logout user, s
            if (payload.resourceType === "action") {
              switch (payload.content) {
                case "logout":
                  store.dispatch("auth/logout", null, { root: true });
                  return;
                case "track":
                  if (userType !== "customer") {
                    Vue.watchPosition();
                    return;
                  }
              }
            }

            switch (userType) {
              case "admin":
                // TODO: refresh statistics
                if (payload.resourceType === "track") {
                  store.dispatch("orders/loadActiveOrders", payload.attributes["storeId"], { root: true });
                } else if (payload.resourceType === "users") {
                  store.dispatch("users/loadUsers", null, { root: true });
                }
                // lowLevelStock admin, n
                // outOfStock admin, n
                // newRegistration admin, b
                // insufficientStock admin, n
                break;
              case "merchant":
              case "manager":
                // TODO: refresh statistics by storeId
                if (payload.tag === "orderLocation") {
                  store.dispatch("orders/loadActiveOrders", payload.attributes["storeId"], { root: true });
                }
                break;
              case "handler":
                break;
              case "driver":
                store.dispatch("orders/" + LOAD_DRIVER_ACTIVE_ORDERS, null, { root: true })
                  .then((data) => {
                    if (data?.length === 0) {
                      store.dispatch("users/" + UNPUBLISH_LOCATION, null, { root: true });
                    } else {
                      store.dispatch("users/" + PUBLISH_LOCATION, null, { root: true });
                    }
                  });
                break;
              case "customer":
                if (payload.tag !== "updateProducts") {
                  store.dispatch("orders/loadMyOrders", null, { root: true });
                }

                if (payload.tag === "orderLocation") {
                  store.dispatch("orders/" + LOAD_MY_ORDERS, null, { root: true });
                }
                break;
            }

            // data reactivity through global pub/sub/unsub,
            // e.g. in admin/stores -> mounted -> this.$root.on("reactivity/STORES", payload)
            if (payload.tags.includes("updateResource")) {
              const scope = payload.attributes["scope"];
              const key = payload.attributes["key"];
              const sKeys = scope.split("/");
              const kValues = key.split("/");
              if (sKeys && sKeys.length > 0 && kValues && kValues.length > 0) {
                for (let sk in sKeys) {
                  payload[sKeys[sk]] = kValues[sk];
                }
              }
              Vue.pub(`reactivity/${payload.content}`, payload);
            }

            // orderUpdate admin s (body = status)
            
            // chat messaging
            // subscribe to new chat rooms
            if(payload.resourceType === "chat" && payload.tags && payload.tags instanceof Array) {
              if(payload.tags.includes("chatMessageAdded")) {
                store.dispatch("chat/" + LOAD_CHAT_ROOM, payload.uuid, { root: true })
              } else if(payload.tags.includes("chatMessageAdded")) {
                
              }

            }
          }
        }
      });


      const regionId = user.regionId;
      // general topics
      const topics = [
        "/message/public",
        `/message/r/${regionId}`,
        // { regionId: user.regionId },
      ];

      // region and store based messages
      if (user.assignedStoreIds) {
        for (let s in user.assignedStoreIds) {
          const storeId = user.assignedStoreIds[s];
          topics.push({ regionId, storeId });
        }
      }

      if (user.roles) {
        for (let r in user.roles) {
          const group = user.roles[r].toLowerCase();
          // region security-based group message
          topics.push({ regionId, group });
          // general security-based group message
          topics.push({ group });
          // private message
          topics.push({ group, uuid: user.uuid });
        }

      }

      // region, store and role based messages
      if (user.assignedStoreIds && user.roles) {
        for (let s in user.assignedStoreIds) {
          for (let r in user.roles) {
            const group = user.roles[r].toLowerCase();
            const storeId = user.assignedStoreIds[s];
            topics.push({ regionId, storeId, group });
          }
        }
      }

      //chat rooms
      switch(userType) {
        case "driver":
        case "customer":
          topics.push({ group: ROOM_DELIVERY, uuid: user.uuid });
          break;
      }

      store.dispatch("socket/" + CONNECT, { uuid: user.uuid, topics }, { root: true });
    }

    Vue.socketConnect = socketConnectFunc;

    Vue.mixin({
      methods: {
        $socketConnect() {
          socketConnectFunc();
        },
      },
    });
  },
};
