<template>
  <v-app id="MAKZANI">
    <router-view></router-view>

    <popup-box />

    <!-- <div class="app-tag">
      <span> Development Mode : 3 </span>
    </div> -->

    <update-bar @update="refreshApp" :updates="appUpdateExists" />
  </v-app>
</template>

<script>
import { VueOfflineMixin } from "vue-offline";
import UpdateBar from "@/common/components/UpdateBar";
import DialogBox from "@/common/components/DialogBox";
import PopupBox from "@/common/components/PopupBox";
import AppUpdate from "@/common/mixins/AppUpdate";
import { SET_OFFLINE } from "@/store/actions/type.ui";

export default {
  name: "App",
  mixins: [VueOfflineMixin, AppUpdate],
  components: { UpdateBar, DialogBox, PopupBox },
  mounted() {
    this.$on("offline", () => {
      this.$store.dispatch(`ui/${SET_OFFLINE}`, true);
    });
    this.$on("online", () => {
      this.$store.dispatch(`ui/${SET_OFFLINE}`, false);
    });
  },
};
</script>

<style>
@import "./assets/style.css";
@import "~@fontsource/changa/400.css";
</style>
