export default {
  coordsRound(value) {
    return Math.round(value * 100000 + Number.EPSILON) / 100000;
  },
  camelize(str) {
    if(!str) {
      return null;
    }

    let first = true;
    return str.replace(/(?:[a-zA-Z]+)/g, function(word, index) {
      const wrd = (first ? word[0].toLowerCase() : word[0].toUpperCase()) + word.substring(1, word.length).toLowerCase();
      first = false;
      return wrd;
    }).replace(/\s+|[_-]|\./g, '');
  },
  formatDate(val) {
    if (!val) {
      return null;
    }
    // const [year, month, day] = d.split("-");
    const d = new Date(val.substring(0, 19));
    const year = d.getYear() + 1900;
    const month = d.getMonth() + 1;
    const day = d.getDate();
    return `${day}/${month}/${year}`;
  },
  formatTime(val) {
    if (!val) {
      return null;
    }
    const d = new Date(val.substring(0, 19));
    const hours = d.getHours();
    const minutes = d.getMinutes();
    // const period = d.getPeriod();
    return `${hours}:${minutes}`;
  },
  notEmptyArray(arr) {
    return arr instanceof Array && !!arr[0];
  },
  compareObjects(o1, o2) {
    for (var a in o1) {
      // eslint-disable-next-line no-prototype-builtins
      if (o1.hasOwnProperty(a)) {
        if (o1[a] !== o2[a]) {
          return false;
        }
      }
    }
    for (var b in o2) {
      // eslint-disable-next-line no-prototype-builtins
      if (o2.hasOwnProperty(b)) {
        if (o1[b] !== o2[b]) {
          return false;
        }
      }
    }
    return true;
  },

  partiallyCompare(original, part) {
    for (var p in part) {
      // eslint-disable-next-line no-prototype-builtins
      if (original.hasOwnProperty(p)) {
        if (original[p] !== part[p]) {
          return false;
        }
      }
    }
    return true;
  },

  sortItemsBy(items, propName) {
    if (!propName) {
      return [...items];
    }

    return items
      ? [...items].sort((x, y) => {
          let a = x[propName] ? x[propName].toString().toUpperCase() : -1,
            b = y[propName] ? y[propName].toString().toUpperCase() : -1;
          return a == b ? 0 : a > b ? 1 : -1;
        })
      : [...items];
  },

  createUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },

  wordWrap(str, maxWidth) {
    const newLineStr = "\n";
    let res = "";
    while (str.length > maxWidth) {
      let found = false;
      // Inserts new line at first whitespace of the line
      for (let i = maxWidth - 1; i >= 0; i--) {
        const white = new RegExp(/^\s$/);
        if (white.test(str.charAt(i))) {
          res = res + [str.slice(0, i), newLineStr].join("");
          str = str.slice(i + 1);
          found = true;
          break;
        }
      }
      // Inserts new line at maxWidth position, the word is too long to wrap
      if (!found) {
        res += [str.slice(0, maxWidth), newLineStr].join("");
        str = str.slice(maxWidth);
      }
    }

    return res + str;
  },
};
