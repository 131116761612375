<template>
  <v-dialog
    v-if="!!getActiveDialog"
    v-model="getActiveDialog.show"
    :width="getActiveDialog.width ? getActiveDialog.width : 330"
    :fullscreen="isFullscreen"
    :persistent="getActiveDialog.persistent"
  >
    <v-card>
      <toolbar :back-button="getActiveDialog.backButton" color="secondary" :height="56">
        <template v-slot:title
          >{{
            getActiveDialog.translate
              ? $t(getActiveDialog.title)
              : getActiveDialog.title
          }}
        </template>
        <div v-if="isFullscreen">
          <v-btn
            v-for="(action, i) in getActiveDialog.actions"
            :key="i"
            depressed
            class="ml-1"
            :color="`${action.color}`"
            @click="dialogButtonAction(action, getActiveDialog.params)"
          >
            {{ action.text }}
          </v-btn>
        </div>

        <v-divider
          :style="`border-width: 1px;
          background: ${getBorderColor};
          position: absolute;
          bottom: 0px;
          left: 0;
          right: 0;`"
        />
      </toolbar>
      <v-card-text :class="!getActiveDialog.noGutter ? 'pa-3' : 'pa-0'">
        {{
          getActiveDialog.translate
            ? $t(getActiveDialog.text)
            : getActiveDialog.text
        }}
        <component
          ref="dialogComponent"
          v-if="getActiveDialog.component"
          :is="getActiveDialog.component"
          v-model="getActiveDialog.params"
        />
        <!-- @update:value="(value) => (dialogModel = value)" -->
        <!-- :params="getActiveDialog.params" -->
      </v-card-text>
      <v-divider />
      <v-card-actions v-if="!isFullscreen">
        <v-alert
          v-if="
            getActiveDialog.params &&
            getActiveDialog.params.validated &&
            !getActiveDialog.params.valid
          "
          border="left"
          colored-border
          type="error"
          dense
          class="body-2"
        >
          Incorrect or missing, please check validation messages.
        </v-alert>
        <v-spacer></v-spacer>
        <v-btn
          v-for="action in getActiveDialog.actions"
          :key="action.text"
          :color="`${action.color}`"
          text
          @click="dialogButtonAction(action, getActiveDialog.params)"
        >
          {{ action.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toolbar from "./Toolbar.vue";

export default {
  name: "DialogBox",
  components: { Toolbar },
  // data() {
  //   return {
  //     dialogModel: {},
  //   };
  // },
  computed: {
    ...mapGetters("ui", ["getActiveDialog", "getActiveNotification"]),

    isFullscreen() {
      return this.$vuetify.breakpoint.xsOnly || this.getActiveDialog.fullscreen;
    },
  },
  methods: {
    ...mapActions("ui", ["refreshDialog"]),

    dialogButtonAction(action, params) {
      if (action.validate) {
        this.$refs.dialogComponent.validate();
        setTimeout(() => action.click(params), 500);
        params.validated = true;
        this.refreshDialog();
      } else {
        action.click(params);
      }
    },
  },
};
</script>
