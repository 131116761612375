import axios from "axios";

export default {
  install(Vue) {
    const readFunc = (file) => {
      return new Promise((resolve, reject) => {
        var rawFile = new XMLHttpRequest();
        rawFile.open("GET", file, false);
        rawFile.onreadystatechange = function() {
          if (rawFile.readyState === 4) {
            if (rawFile.status === 200 || rawFile.status == 0) {
              resolve(rawFile.responseText);
            } else {
              reject();
            }
          }
        };
        rawFile.send(null);
      })
    };

    const uploadFunc = (url, file, name, meta) => {
      if (typeof url !== "string") {
        throw new TypeError(`Expected a string, got ${typeof url}`);
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);
      formData.append("meta", meta);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      return axios.post(url, formData, config);
    };

    Vue.upload = uploadFunc;

    Vue.mixin({
      methods: {
        $upload(url, file, name, meta) {
          return uploadFunc(url, file, name, meta);
        },
        $readFile(file) {
          return readFunc(file);
        },
      },
    });
  },
};
