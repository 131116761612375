import { Clipboard } from '@capacitor/clipboard';

export default {
  install(Vue) {
    const copyFunc = async (value) => {
      await Clipboard.write({
        string: value
      });
    };
    const pasteFunc = async () => {
      const { value } = await Clipboard.read();
      return value;
    };
    
    Vue.copy = copyFunc;
    Vue.paste = pasteFunc;
    Vue.mixin({
      methods: {
        $copy(value) {
          return copyFunc(value);
        },
        $paste() {
          return pasteFunc();
        },
      },
    });
  },
};
