// actions & mutations
export const LOAD_ORDERS = "loadOrders";
export const LOAD_ORDERS_COMPLETED = "loadOrdersCompleted";
export const LOAD_MY_ORDERS = "loadMyOrders";
export const LOAD_MY_ORDERS_COMPLETED = "loadMyOrdersCompleted";
export const LOAD_ACTIVE_ORDERS = "loadActiveOrders";
export const LOAD_ACTIVE_ORDERS_COMPLETED = "loadActiveOrdersCompleted";
export const LOAD_DRIVER_ACTIVE_ORDERS = "loadDriverActiveOrders";
export const LOAD_DRIVER_ACTIVE_ORDERS_COMPLETED = "loadDriverActiveOrdersCompleted";
export const SET_SELECTED_ORDER = "setSelectedOrder";
export const CLEAR_SELECTED_ORDER = "clearSelectedOrder";
export const COLLAPSE_ALL = "collapseAll";
export const EXPAND_ALL = "expandAll";
export const UPDATE_ORDER_LOCATION = "updateOrderLocation";
export const UPDATE_ORDER_STATUS = "updateOrderStatus";

// getters
export const GET_LATEST_LOCATION = "getLatestLocation";
export const GET_MY_ORDERS = "getMyOrders";
export const GET_MY_ORDER_BY_ID = "getMyOrderById";
export const GET_MY_ORDER_BY_UUID = "getMyOrderByUuid";
export const GET_ACTIVE_ORDERS = "getActiveOrders";
export const GET_ACTIVE_ORDERS_BY_STATUS = "getActiveOrdersByStatus";
export const GET_ACTIVE_ORDERS_BY_DRIVER = "getActiveOrdersByDriver";
export const GET_SELECTED_ORDER = "getSelectedOrder";

export const GET_DRIVER_ACTIVE_ORDERS = "getDriverActiveOrders";


// status types
export const STATUS_WAITING = "WAITING";
export const STATUS_CANCELLED = "CANCELLED";
export const STATUS_PREPARING = "PREPARING";
export const STATUS_LOADING = "LOADING";
export const STATUS_DISPATCHED = "DISPATCHED";
export const STATUS_DELIVERY = "DELIVERY";
export const STATUS_NEAR = "NEAR";
export const STATUS_DELIVERED = "DELIVERED";
export const STATUS_RETURNED = "RETURNED";
export const STATUS_SWITCH = "SWITCH";
export const STATUS_NOT_ACCEPTED = "NOT ACCEPTED";

