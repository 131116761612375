import { apiActions } from "@/services";
import * as actionTypes from "../actions/type.stores";
import crud from "./crud";

const stores = crud("Store", "units/stores", "regionId");

const state = {
  ...stores.state,

  openStoreId: 0,
  assignedStores: [],
  loadingAssignedStores: false,
  loadedAssignedStoresSuccessfully: false,
};

const getters = {
  ...stores.getters,

  [actionTypes.GET_OPEN_STORE_ID]: (state) => {
    return state.openStoreId;
  },
  [actionTypes.GET_OPEN_STORE]: (state, getters) => {
    return getters[actionTypes.GET_STORE_BY_ID](state.openStoreId);
  },
  [actionTypes.GET_STORE_BY_ID_AND_REGION_ID]: (state) => (id, regionId) => {
    return state.items.find((f) => f.id === id && f.regionId === regionId);
  },
  [actionTypes.GET_STORE_BY_ID]: (state) => (id) => {
    return state.items.find((f) => f.id === id);
  },
  [actionTypes.GET_USER_ASSIGNED_STORES]: (state) => {
    return state.assignedStores;
  },
};

const actions = {
  ...stores.actions,

  [actionTypes.OPEN_STORE]({ commit }, id) {
    commit(actionTypes.OPEN_STORE, id);
  },
  async [actionTypes.LOAD_USER_ASSIGNED_STORES]({ commit }) {
    return apiActions.load(
      "units/stores/assigned",
      null,
      commit,
    actionTypes.LOAD_USER_ASSIGNED_STORES);
  },
  async [actionTypes.LOAD_STORE]({ commit }, storeId) {
    return apiActions.load(
      `units/stores/${storeId}`,
      null,
      commit,
    actionTypes.LOAD_STORE);
  },
  [actionTypes.RELOAD_STORES]({ commit }, storeIds) {
    return apiActions.load(
      `units/stores/${storeIds}`,
      null,
      commit,
    actionTypes.RELOAD_STORES);
  },
  [actionTypes.CLEAR_STORES]({ commit }) {
    commit(actionTypes.CLEAR_STORES);
  },
  async [actionTypes.IS_LOCATION_IN_AOS]({ commit }, params) {
    const { storeId, latitude, longitude } = params;
    return apiActions.load(
      `units/stores/${storeId}/in.aos/${latitude}/${longitude}`,
      null,
      commit);
  },
};

const mutations = {
  ...stores.mutations,

  [actionTypes.OPEN_STORE](state, payload) {
    state.openStoreId = payload;
  },
  [actionTypes.OPEN_STORE](state, payload) {
    state.openStoreId = payload;
  },
  [actionTypes.LOAD_USER_ASSIGNED_STORES](state) {
    state.loadedAssignedStoresSuccessfully = false;
    state.loadingAssignedStores = true;
  },
  [actionTypes.LOAD_USER_ASSIGNED_STORES_COMPLETED](state, payload) {
    state.loadedAssignedStoresSuccessfully = payload.success;
    state.loadingAssignedStores = false;
    state.assignedStores = payload.data;
  },
  [actionTypes.LOAD_STORE](state) {
    state.loadedAssignedStoresSuccessfully = false;
    state.loadingAssignedStores = true;
  },
  [actionTypes.LOAD_STORE_COMPLETED](state, payload) {
    state.loadedAssignedStoresSuccessfully = payload.success;
    state.loadingAssignedStores = false;
    // add or update stores with the loaded store
    state.items = [...state.items.filter((m) => m.id !== payload.data.id), payload.data];
    state.assignedStores = [...state.assignedStores.filter((m) => m.id !== payload.data.id), payload.data];
  },
  [actionTypes.RELOAD_STORES](state) {
    state.loadedAssignedStoresSuccessfully = false;
    state.loadingAssignedStores = true;
  },
  [actionTypes.RELOAD_STORES_COMPLETED](state, payload) {
    state.loadedAssignedStoresSuccessfully = payload.success;
    state.loadingAssignedStores = false;
    // add or update stores with the loaded store
    state.items = [...state.items.filter((m) => !payload.data.some(s => s.id === m.id)), ...payload.data];
    state.assignedStores = [...state.assignedStores.filter((m) => !payload.data.some(s => s.id === m.id)), ...payload.data];
  },
  [actionTypes.CLEAR_STORES](state) {
    state.loadedAssignedStoresSuccessfully = false;
    state.loadingAssignedStores = false;
    state.assignedStores = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
