// access control actions
export const SAVE_ROLES = "saveRoles";
export const LOAD_ROLES = "loadRoles";
export const LOAD_ROLES_COMPLETED = "loadRolesCompleted";
export const LOAD_PERMISSIONS = "loadPermissions";
export const LOAD_PERMISSIONS_COMPLETED = "loadPermissionsCompleted";
export const SET_ROLES = "setRoles";
export const SAVE_ROLE = "saveRole";
export const DELETE_ROLE = "deleteRole";
export const SET_SELECTED_ROLE = "setSelectedRole";
export const CLEAR_SELECTED_ROLE = "clearSelectedRole";

export const GET_SELECTED_ROLE = "getSelectedRole";
export const GET_ROLES = "getRoles";
export const GET_PERMISSIONS = "getPermissions";
export const GET_ROLE_NAMES = "getRoleNames";
export const GET_ROLE_BY_ID = "getRoleById";
