import { apiService } from "@/services";
import Vue from 'vue'

class ApiActions {
  async load(url, params, commit, action, options) {
    if(commit && action && !options?.skipInitMutation) {
      commit(action);
    }
    
    return new Promise((resolve, reject) => {
      apiService.query(url, params)
        .then(({ data, status }) => {
          if(commit && action && !options?.skipCompletedMutation) {
              commit(action + "Completed", {
              success: status === 200,
              data,
              params,
            });
          }

          if (options?.onSuccess) {
            options.onSuccess(data);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          if(commit && action) {
              commit(action + "Completed", {
              success: false,
              data: [],
              params,
            });
          }
          
          if (options?.onError) {
            options.onError(response);
          }

          reject(response);
        });
    });
  }

  async save(url, params, commit, action, options) {
    if (!params) {
      return;
    }

    const { editMode, item } = params;

    if (editMode) {
      return apiService.patch(url, item)
        .then(({ data }) => {
          if (commit && action) {
            commit(action, { editMode, payload: data, params });
          }

          if (options && options.onSuccess) {
            options.onSuccess(data);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.updateDone", "success");
          }
        })
        .catch((error) => {
          if (options && options.onError) {
            options.onError(error);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.operationError", "error");
          }
          throw new Error(error);
        });
    } else {
      return apiService.post(url, item)
        .then(({ data }) => {
          if(commit && action) {
            commit(action, { editMode, payload: data, params });
          }

          if (options && options.onSuccess) {
            options.onSuccess(data);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.operationError", "error");
          }
        })
        .catch((error) => {
          if (options && options.onError) {
            options.onError(data);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.operationError", "error");
          }
          throw new Error(error);
        });
    }
  }

  async add(url, params, commit, action, options) {
    if (!params) {
      return;
    }

    return this.action(url, params, commit, action, options);
  }

  async action(url, params, commit, action, options) {
    return apiService.post(url, params)
      .then(({ data, status }) => {
        if (commit && action) {
          commit(action, {
            success: status === 200,
            payload: data,
            params,
         });
        }

        if (options && options.onSuccess) {
          options.onSuccess(data);
        }

        if (!options || !options.silent) {
          Vue.popup(
            (options && options.successMessage) || "messages.done",
            "success"
          );
        }
      })
      .catch((error) => {
        if (options && options.onError) {
          options.onError(error);
        }

        if (!options || !options.silent) {
          Vue.popup(
            (options && options.errorMessage) || "messages.operationError",
            "error"
          );
        }
      });
  }

  async update(url, params, commit, action, options) {
    return new Promise((resolve, reject) => {
      apiService.patch(url, params)
        .then(({ data, status }) => {
          if (commit && action) {
            commit(action, { success: status === 200, payload: data, params });
          }

          if (options && options.onSuccess) {
            options.onSuccess(data);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.updateDone", "success");
          }
          resolve(data);
        })
        .catch((error) => {
          if (options && options.onError) {
            options.onError(error);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.operationError", "error");
          }
          reject(error);
        });
    });
  }

  async delete(url, id, commit, action, options) {
    return new Promise((resolve, reject) => {
      apiService.delete(url, id)
        .then(({ data, status }) => {
          if (commit && action) {
            commit(action, { success: status === 200, payload: id });
          }

          if (options && options.onSuccess) {
            options.onSuccess(data);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.deleteCompleted", "black");
          }
          resolve(data);
        })
        .catch((error) => {
          if (options && options.onError) {
            options.onError(error);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.operationError", "error");
          }
          reject(error);
        });
    });
  }
}

export const apiActions = new  ApiActions();
