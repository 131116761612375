export default {
  data() {
    return {
      appRefreshing: false,
      appRegistration: null,
      appUpdateExists: false,
    }
  },

  created() {
    // Listen for our custom event from the SW appRegistration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.appRefreshing) {
        return
      }
      
      this.appRefreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  },

  methods: {
    // Store the SW appRegistration so we can send it a message
    // We use `appUpdateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.appRegistration = event.detail
      this.appUpdateExists = true
    },

    // Called when the user accepts the update
    refreshApp() {
      this.appUpdateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.appRegistration || !this.appRegistration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.appRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
  },
}