import { CHECK_AUTH, LOGOUT } from "@/store/actions/type.auth";
import {
  SHOW_DIALOG,
  CLOSE_DIALOG,
  SHOW_FEEDBACK,
  DISMISS_FEEDBACK,
} from "@/store/actions/type.ui";
import { OPEN_STORE } from "@/store/actions/type.stores";
import { SUBSCRIBE, UNSUBSCRIBE } from "@/store/actions/type.socket";

export default {
  install(Vue, { store, router }) {
    let isBackButtonPressed = false;

    window.addEventListener('popstate', () => {
      isBackButtonPressed = true;
    });

    const controlRouteFunc = (to, from, next) => {
      console.log("isBackButtonPressed:", isBackButtonPressed);

      if (to.name === from.name) {
        return;
      }

      if (to.matched.length === 0) {
        setTimeout(() => {
          const notification = {
            text: "Resource not found",
            actionText: "OK",
            action: () => {
              store.dispatch(`ui/${DISMISS_FEEDBACK}`);
            },
          };
          store.dispatch(`ui/${SHOW_FEEDBACK}`, notification);
        }, 500);

        if (!from.name) {
          // TODO: go to 404
          router.go(-1);
        } else {
          return;
        }
      }

      if (!!to.meta.resource) {
        store
          .dispatch(`auth/${CHECK_AUTH}`)
          .then(() => {
            if (!store.state.auth.isAuthenticated && to.name !== "login" && to.name !== "alt-login") {
              router.push({ name: "login" });
            }

            const rolesAuthorized =
              to.meta.resource.indexOf("{route}") === -1
                ? Vue.allowed(to.meta.resource)
                : Vue.allowed(
                  to.meta.resource.replace(
                    "{route}",
                    to.params.route.replace("-", ".")
                  )
                );

            if (!rolesAuthorized && to.name !== "login" && to.name !== "alt-login") {
              if (
                (to.name === "pin" &&
                  store.state.auth.user.phoneConfirmed) ||
                (to.name === "email" &&
                  store.state.auth.user.emailConfirmed)
              ) {
                router.push({ name: "default" });
              } else if (to.name === "default" || !to.name) {
                router.push({ name: "login" });
              } else {
                const notification = {
                  text: "messages.accessDenied",
                  actionText: "OK",
                  action: () => {
                    store.dispatch(`ui/${DISMISS_FEEDBACK}`);
                  },
                };
                store.dispatch(`ui/${SHOW_FEEDBACK}`, notification);
                if (from.name) {
                  router.go(-1);
                }
              }
            } else if (!store.state.auth.user.phoneConfirmed) {
              // TODO: go to message "confirm phone number"
              store.dispatch(`auth/${LOGOUT}`);
              // router.push({ name: "login" });
            } else if (
              to.name !== "select-region" &&
              !store.state.auth.user.regionId
            ) {
              router.push({
                name: "select-region",
                params: { back: false },
              });
            } else if (
              store.state.auth.user.regionId &&
              to.name !== "addresses" &&
              to.name !== "address" &&
              !store.state.auth.user.activeAddress
            ) {
              router.push({
                name: "addresses",
                params: { back: false, initAccount: true },
              });
            } else {
              const closeStoreId = from.params ? parseInt(from.params.storeId) : null;
              const openStoreId = to.params ? parseInt(to.params.storeId) : null;
              store.dispatch(`stores/${OPEN_STORE}`, openStoreId, { root: true });
              if (closeStoreId && closeStoreId !== openStoreId) {
                store.dispatch(`socket/${UNSUBSCRIBE}`, [{ storeId: closeStoreId }], { root: true });
              } else if (openStoreId) {
                store.dispatch(`socket/${SUBSCRIBE}`, [{ storeId: openStoreId }], { root: true });
              }

              isBackButtonPressed = false;
              next();
            }
        })
          .catch((error) => {
            console.log(error);
            // router.push({ name: "login" });
          });
      } else if (
        store.state.auth.isAuthenticated &&
        (to.name === "login" ||
          to.name === "alt-login" ||
          to.name === "register")
      ) {
        const dialog = {
          title: "Logged In",
          text:
            "You are currently logged in. Do you want to log out before proceeding?",
          actions: [
            {
              text: "Yes",
              click: () => {
                store.dispatch(`auth/${LOGOUT}`);
                store.dispatch(`ui/${CLOSE_DIALOG}`);

                isBackButtonPressed = false;
                next();
              },
              color: "primary",
            },
            {
              text: "No",
              click: () => {
                store.dispatch(`ui/${CLOSE_DIALOG}`);
                if (to.name !== "login") {
                  router.go(-1);
                }
              },
              color: "primary",
            },
          ],
        };

        store.dispatch(`ui/${SHOW_DIALOG}`, dialog);
      } else {
        const loginPhone = window.localStorage.getItem("loginPhone");
        const registerPhone = window.localStorage.getItem("registerPhone");

        if (to.name === "pin" && !loginPhone && !registerPhone) {
          router.push({ name: "login" });
        } else if (to.name === "login" && !!loginPhone) {
          router.push({
            name: "pin",
            query: { phone: loginPhone, isLogin: true },
          });
        } else if (to.name === "register" && !!registerPhone) {
          router.push({
            name: "pin",
            query: { phone: registerPhone, registrationType: "phone" },
          });
        } else {
          isBackButtonPressed = false;
          next();
        }
      }
    };

    router.beforeEach(controlRouteFunc);

    // Vue.controlRoute = controlRouteFunc;
    // Vue.mixin({
    //   methods: {
    //     $controlRoute(to, from, next) {
    //       return controlRouteFunc(to, from, next);
    //     },
    //   },
    // });
  },
};
