import Vue from "vue";
import App from "./App";
import router from "./router";
import vuetify from "./plugins/vuetify";
import fullscreen from "vue-fullscreen";
import i18n from "./locales";
import { apiService } from "./services";
import store from "./store";
import util from "./common/helpers/util";
import { HANDSHAKE_SERVER, HEARTBEAT } from "./store/actions/type.auth";
import { SET_LANGUAGE, SET_THEME } from "./store/actions/type.ui";
import {
  Authorization,
  Geo,
  Upload,
  // WebSocket,
  EventsBus,
  Messaging,
  Routing,
  Clipboard,
  Notification,
  FormValidation,
  setupBackButtonListener,
} from "./plugins";
import VueOffline from "vue-offline";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "@capacitor/core";
import "./registerServiceWorker";
import { authorizationService } from "@/services";

Vue.config.productionTip = false;

// Ensure we checked auth before each page load.
Vue.use(Authorization);
Vue.use(Geo, { store });
Vue.use(Clipboard);
Vue.use(Upload);
// Vue.use(WebSocket);
Vue.use(EventsBus);
Vue.use(Messaging, { store });
Vue.use(Routing, { store, router });
Vue.use(VueOffline, { mixin: false });
Vue.use(fullscreen);
Vue.use(Notification, { store });
Vue.use(FormValidation);
Vue.socketConnect();
Vue.permitGeo();

// const HOME_PAGE = 
// const appType = process.env.VUE_APP_TYPE ? process.env.VUE_APP_TYPE.toLowerCase() : null;
// const HOME_PAGE = appType === "driver" ? "/delivery/active" :
// appType === "store-manager" ? "/business/stores/dispatchers" :
// "/stores";
const HOME_PAGE = authorizationService.hasRole("DRIVER") ?
  "/delivery/active" :
  authorizationService.hasRole("DRIVERSTORE-MANAGER") ?
  "/business/stores/dispatchers":
  "/stores";

console.log(HOME_PAGE);

setupBackButtonListener(HOME_PAGE);

// const { Device } = Plugins;
// const init = async () => {
//   const device = await Device.getInfo();
//   const debug = process.env.NODE_ENV !== "production";
//   let url = process.env.VUE_APP_API_BASE_URL;
//   if (debug && device.platform !== "web") {
//     url = process.env.VUE_APP_API_MOBILE_BASE_URL;
//   }
// }
// init();
// let url = process.env.VUE_APP_API_BASE_URL;
apiService.init(store);

//initiate first handshake with server
store.dispatch(`auth/${HANDSHAKE_SERVER}`, null, { root: true });

// send online-status update
setInterval(() => {
  store.dispatch(`auth/${HEARTBEAT}`, null, { root: true });

  // check socket connection
  // if(!store.state.socket.connected) {
  //   console.log("socket disconnected, attempting reconnect...")
  //   Vue.socketConnect();
  // }

}, 20000);

Vue.mixin({
  methods: {
    ...util,
    getServerFile(path) {
      return `${this.getApiBaseUrl}/files/image/?path=${path}`;
    },
    changeLanguage(lang) {
      store.dispatch(`ui/${SET_LANGUAGE}`, lang);
      this.$i18n.locale = lang;
      this.$vuetify.rtl = lang === "ar";
    },
    getBorderColor() {
      return this.$vuetify.theme.themes.light.secondary + "75";
    },
    initTheme() {
      let theme = "default";
      if (this.$hasRole("DRIVER")) {
        theme = "driver";
      } else if (this.$hasRole("ADMIN") || this.$hasRole("SUPER-ADMIN")) {
        theme = "admin";
      }

      store.dispatch(`ui/${SET_THEME}`, theme);
      this.$vuetify.theme.themes.name = theme;
      const t = store.state.ui.themes.find((f) => f.name === theme);
      if (t) {
        for (var i in this.$vuetify.theme.themes.light) {
          this.$vuetify.theme.themes.light[i] = t.light[i];
        }
      }
    },
    getViewHeightStyle(mdHeight = 56, gap = 20) {
      return `height: calc(100vh - ${mdHeight}px); max-height: calc(100vh - ${mdHeight}px);`;
      // if (this.$vuetify.breakpoint.mdAndDown) {
      // } else {
      //   return `height: calc(100vh - ${mdHeight + gap}px); max-height: calc(100vh - ${mdHeight + gap}px);`;
      // }
    },
  },
  computed: {
    homePage() {
      return this.$hasRole("DRIVER") ? "delivery" : "stores";
    },
    getLocale() {
      return {
        lang: this.$i18n.locale,
        rtl: this.$vuetify.rtl === "ar",
      };
    },
    getApiBaseUrl() {
      return process.env.VUE_APP_API_BASE_URL;
    },
    isEditMode() {
      return parseInt(this.$route.params.id) > 0;
    },
    isMd() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    getVersion() {
      return process.env.VUE_APP_VERSION;
    },
    getOptions() {
      return {
        products: {
          showRating: false,
          showDescription: true,
          showTags: false,
        },
      };
    },
    isNative() {
      return platform.isNative;
    },
    isAndroid() {
      return platform.is('android');
    },
    isAndroid() {
      return platform.is('ios');
    },
  },
});

const app = new Vue({
  router,
  store,
  vuetify,
  Authorization,
  Upload,
  i18n,
  L,
  render: (h) => h(App),
}).$mount("#app");

app.changeLanguage(store.state.ui.language);
app.initTheme();
