<template>
  <div>
    <v-toolbar flat dense :color="color || 'grey lighten-5'" class="toolbar" :height="height">
      <v-btn icon v-if="backButton" @click="moveBack">
        <v-icon large>mdi-arrow-left</v-icon>
      </v-btn>
      <v-divider
        vertical
        inset
        class="d-none d-md-flex mx-2"
        v-if="backButton"
      />
      <v-toolbar-title v-if="title" class="py-3 px-0">
        <span v-if="editMode && editor">
          {{ $t("general.edit") + ": " + (title ? title : "") }}
        </span>
        <span v-if="!editMode && editor">
          {{ $t("general.new") + ": " + (title ? title : "") }}
        </span>
        <span v-if="!editor">
          {{ title }}
        </span>
        <v-icon v-show="editMode && changed" color="primary">
          mdi-textbox-password
        </v-icon>
      </v-toolbar-title>
      <slot v-if="!title" name="title"></slot>
      
      <v-spacer v-if="!stretch" />

      <!-- <v-chip class="px-2" small label v-if="readOnly" color="yellow">
        <v-avatar left>
          <v-icon small>mdi-alert</v-icon>
        </v-avatar>
        {{ $t("general.readonly") }}
      </v-chip> -->
      <slot></slot>
    </v-toolbar>
    <v-progress-linear
      v-if="loading"
      color="secondary"
      height="2"
      :indeterminate="loading"
    ></v-progress-linear>
  </div>
</template>

<script>
export default {
  name: "Toolbar",
  props: {
    title: String,
    backButton: Boolean,
    stretch:Boolean,
    editor: Boolean,
    editMode: Boolean,
    readOnly: Boolean,
    changed: Boolean,
    showKeepOpen: Boolean,
    height: Number,
    color: String,
    loading: Boolean,
  },
  methods: {
    moveBack() {
      let back = null;
      if(this.$route.params.back) {
        back = this.$route.params.back;
      } else {
        back = this.$route.meta.back;
      }

      if (back) {
        if(back.params) {
          for (var p in back.params) {
            console.log("p:", p);
            back.params[p] = this.$route.params[p];
          }
        }
        this.$router.replace({ name: back.name, params: back.params || {} });
      } else {
        this.$router.go(-1);
      }
    },
  }
};
</script>

<style>
.v-toolbar__content .v-btn {
  padding: 0 2px !important;
  margin: 0 3px !important;
}

.toolbar span,
.toolbar label {
  font-size: 8pt;
  text-transform: uppercase;
}

.v-toolbar__title span {
  font-size: 14pt;
  text-transform: none;
}
</style>
