import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VToolbar,{staticClass:"toolbar",attrs:{"flat":"","dense":"","color":_vm.color || 'grey lighten-5',"height":_vm.height}},[(_vm.backButton)?_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.moveBack}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-arrow-left")])],1):_vm._e(),(_vm.backButton)?_c(VDivider,{staticClass:"d-none d-md-flex mx-2",attrs:{"vertical":"","inset":""}}):_vm._e(),(_vm.title)?_c(VToolbarTitle,{staticClass:"py-3 px-0"},[(_vm.editMode && _vm.editor)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("general.edit") + ": " + (_vm.title ? _vm.title : ""))+" ")]):_vm._e(),(!_vm.editMode && _vm.editor)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("general.new") + ": " + (_vm.title ? _vm.title : ""))+" ")]):_vm._e(),(!_vm.editor)?_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(_vm.editMode && _vm.changed),expression:"editMode && changed"}],attrs:{"color":"primary"}},[_vm._v(" mdi-textbox-password ")])],1):_vm._e(),(!_vm.title)?_vm._t("title"):_vm._e(),(!_vm.stretch)?_c(VSpacer):_vm._e(),_vm._t("default")],2),(_vm.loading)?_c(VProgressLinear,{attrs:{"color":"secondary","height":"2","indeterminate":_vm.loading}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }