import * as actionTypes from "../actions/type.socket";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

const API_URL = process.env.VUE_APP_API_BASE_URL;

let sock = new SockJS(`${API_URL}/ws`);
let client = Stomp.over(sock);

export const generateTopic = (params) => {
  if (typeof params === "string") {
    return params;
  }

  const regionGroup = "/message/r/:regionId/role/:group";
  const group = "/message/role/:group";
  const user = "/message/role/:group/id/:uuid";
  const storeGroup = "/message/r/:regionId/s/:storeId/role/:group";
  const store = "/message/r/:regionId/s/:storeId/general";

  let url = params.uuid ?
    user :
    params.storeId && params.group ?
      storeGroup :
      params.storeId ?
        store :
        params.regionId && params.group ?
          regionGroup :
          params.group ? group : "";
  const paramsMatches = url.match(/:(\w+)\/?/g) || [];
  const urlParams = paramsMatches.map((m) => m.replace(/:|\//g, ""));

  for (var pi in urlParams) {
    const paramName = urlParams[pi];
    const p = params[paramName];
    url = url.replace(new RegExp(":" + paramName), p);
  }
  return url;
};

const state = {
  connected: false,
  connectedBy: null,
  topics: [],
  messages: [],
};

const getters = {
  [actionTypes.GET_NEW_MESSAGE](state) {
    return state.messages.find((f) => f.new && f.from !== state.connectedBy);
  },
  [actionTypes.GET_MESSAGES](state) {
    return state.messages;
  },
  [actionTypes.GET_MESSAGE_BY_ID]: (state) => (uuid) => {
    return state.messages.find((f) => f.uuid === uuid);
  },
  [actionTypes.GET_BACKGROUND_MESSAGES](state) {
    return state.messages.filter((f) => f.background);
  },
  [actionTypes.GET_MESSAGES_BY_TOPIC]: (state) => (topic) => {
    return state.messages.filter((f) => f.topic === topic);
  },
};

const actions = {
  [actionTypes.CONNECT]({ commit, dispatch, state, rootState }, { uuid, topics }) {
    sock = new SockJS(`${API_URL}/ws`);
    client = Stomp.over(sock);
    
    const user = rootState.auth.user;
    const token = user ? user.token : null;
    if (!token) {
      return;
    }

    client.connect({'Authorization': 'Bearer ' + token}, () => {
      commit(actionTypes.CONNECTED, uuid);

      if (topics && topics instanceof Array) {
        for (let t in topics) {
          const topic = topics[t];
          let url = topic;
          if (typeof topic === "object") {
            url = generateTopic(topic);
          }

          if(!url) {
            continue;
          }

          // subscribe to a topic
          client.subscribe(url, message => {
            // parse the message body as JSON
            const body = JSON.parse(message.body)
            // commit the message to the store
            commit(actionTypes.MESSAGE, body);
          }, { ack: 'auto' });
          commit(actionTypes.SUBSCRIBE, url);
        }
      }

      // client.onDisconnect = () => {
      //   console.log("socket disconnected, attempting to reconnect...")
      //   // handle disconnection by attempting to reconnect
      //   dispatch(`socket/${actionTypes.CONNECT}`, {uuid, topics});
      //   // client.connect({}, () => {
      //   //   // re-subscribe to the topic when the connection is re-established
      //   //   for (let t in state.topics) {
      //   //     const stateTopic = state.topics[t];
      //   //     let url = stateTopic;
      //   //     if (typeof stateTopic === "object") {
      //   //       url = generateTopic(stateTopic);
      //   //     }

      //   //     client.subscribe(stateTopic);
      //   //     commit(actionTypes.SUBSCRIBE, url);
      //   //   }
      //   // })
      // }
    }, (debug) => {
      console.log(debug);
      commit(actionTypes.DISCONNECT);

      // handle disconnection by attempting to reconnect
      setTimeout(() => {
        dispatch(actionTypes.CONNECT, {uuid, topics});
      }, 10000)
    });
  },
  [actionTypes.SUBSCRIBE]({ commit, state }, topics) {
    if (!state.connected) {
      return;
    }

    for (let t in topics) {
      const topic = topics[t];
      let url = topic;
      if (typeof topic === "object") {
        url = generateTopic(topic);
      }

      client.subscribe(url, message => {
        // parse the message body as JSON
        const body = JSON.parse(message.body)
        // commit the message to the store
        commit(actionTypes.MESSAGE, body);
      }, { ack: 'auto' });
      commit(actionTypes.SUBSCRIBE, url);
    }
  },
  [actionTypes.UNSUBSCRIBE]({ commit }, topics) {
    if (!state.connected) {
      return;
    }

    for (let t in topics) {
      const topic = topics[t];
      let url = topic;
      if (typeof topic === "object") {
        url = generateTopic(topic);
      }

      this.client.unsubscribe(url);
      commit(actionTypes.UNSUBSCRIBE, url);
    }
  },
  [actionTypes.SEND_MESSAGE]({ commit }, { topic, message }) {
    this.client.send(topic, message);
    commit(actionTypes.MESSAGE, { ...message, sent: true });
  },
  [actionTypes.RECEIVED_MESSAGE]({ commit }, message) {
    commit(actionTypes.MESSAGE, { ...message, received: true });
  },
  [actionTypes.READ_MESSAGE]({ commit }, message) {
    commit(actionTypes.READ_MESSAGE, { ...message, new: false });
  },
  [actionTypes.DELETE_MESSAGE]({ commit }, message) {
    commit(actionTypes.DELETE_MESSAGE, message);
  },
  [actionTypes.CLEAR_MESSAGES]({ commit }, topic) {
    commit(actionTypes.CLEAR_MESSAGES, topic);
  },
  [actionTypes.DISCONNECT]({ commit }) {
    commit(actionTypes.DISCONNECT);
  },
};

const mutations = {
  [actionTypes.CONNECTED](state, payload) {
    state.connected = true;
    state.connectedBy = payload;
  },
  [actionTypes.DISCONNECT](state) {
    state.connected = false;
    state.connectedBy = null;
    state.messages = [];
    state.topics = [];
  },
  // [actionTypes.REGISTERED](state, payload) {
  //   state.connected = true;
  //   state.connectedBy = payload;
  // },
  [actionTypes.SUBSCRIBE](state, payload) {
    const topic = state.topics.find((f) => f === payload);
    if (!topic) {
      state.topics = [...state.topics, payload];
    }
  },
  [actionTypes.UNSUBSCRIBE](state, payload) {
    state.topics = state.topics.filter((f) => f !== payload);
  },
  [actionTypes.MESSAGE](state, payload) {
    state.messages = [...state.messages, { ...payload, new: true }];
  },
  [actionTypes.READ_MESSAGE](state, payload) {
    state.messages = state.messages.map((m) => {
      if (m.uuid === payload.uuid) {
        return payload;
      }
      return m;
    });
  },
  [actionTypes.DELETE_MESSAGE](state, payload) {
    state.messages = state.messages.filter((f) => f.uuid !== payload.uuid);
  },
  [actionTypes.CLEAR_MESSAGES](state, payload) {
    if(payload === "ALL") {
      state.messages = [];
    } else {
      state.messages = state.messages.filter((f) => f.topic === payload)
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
