import mitt from "mitt";

export default {
  install(Vue) {
    const emitter = mitt();

    const pubFunc = (eventName, payload) => {
      emitter.emit(eventName, payload);
    };
    const subFunc = (eventName, callback) => {
      emitter.on(eventName, callback);
    };
    const unsubFunc = (eventName, callback) => {
      emitter.off(eventName, callback);
    };

    const unsubAllFunc = (callback) => {
      emitter.off(emitter.all, callback);
    };

    Vue.pub = pubFunc;
    Vue.sub = subFunc;
    Vue.unsub = unsubFunc;
    Vue.unsubAll = unsubAllFunc;

    Vue.mixin({
      methods: {
        $pub(eventName, payload) {
          return pubFunc(eventName, payload);
        },
        $sub(eventName, callback) {
          return subFunc(eventName, callback);
        },
        $unsub(eventName, callback) {
          return unsubFunc(eventName, callback);
        },
        $unsubAll(callback) {
          return unsubAllFunc(callback);
        },
      },
    });
  },
};
