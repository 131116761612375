import store from "@/store";

class AuthorizationService {
  hasRole(roleName) {
    const { user } = store.state.auth;
    const result = user && user.roles && user.roles.includes(roleName);
    return result;
  };
  authorized() {
    return store.state.auth.isAuthenticated;
  };
  getUserInfo() {
    const user = { ...store.state.auth.user };
    delete user.token;

    return user;
  };
  allowed(permission) {
    const { isAuthenticated } = store.state.auth;
    const { roles: userRoles } = store.state.auth.user;
    // TODO: roles local data persistance to be encrypted and frequently updated
    if (isAuthenticated && userRoles.includes("SUPER-ADMIN")) {
      return true;
    }

    const { roles: aclRoles } = store.state.roles;
    if (!aclRoles || aclRoles.length === 0 || !isAuthenticated) {
      return false;
    }

    const targetRoles = aclRoles.filter((f) => userRoles.includes(f.name));
    if (targetRoles != null && targetRoles.length > 0) {
      const hasMatch = targetRoles.some(
        (r) =>
          r.permissions != null &&
          r.permissions.some(
            (p) =>
              p.name === permission ||
              p.name.replace(/\s/g, "").toLowerCase() ===
              permission.replace(/\s/g, "").toLowerCase()
          )
      );

      return hasMatch;
    }

    return false;
  };

  generateStoreTopics(storeId) {
    const topics = [];
    const { regionId, roles } = store.state.auth.user;
    if (roles) {
      for (let r in roles) {
        const group = roles[r].toLowerCase();
        topics.push({ regionId, storeId, group });
      }
    }
    return topics;
  };

}

export const authorizationService = new AuthorizationService();