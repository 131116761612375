import * as actionTypes from "../actions/type.files";
import { apiActions } from "@/services";

const API_PATH = "files";

const state = {
  filesTree: [],
  loadingFilesTree: false,
};

const getters = {
  [actionTypes.GET_FILES_TREE](state) {
    return state.filesTree;
  },
  [actionTypes.IS_LOADING_FILES_TREE](state) {
    return state.loadingFilesTree;
  },
};

const actions = {
  async [actionTypes.LOAD_FILES_TREE]({ commit }, path) {
    return apiActions.load(
      `${API_PATH}/tree/?path=${path}`,
      null,
      commit,
    actionTypes.LOAD_FILES_TREE);
  },
};

const mutations = {
  [actionTypes.LOAD_FILES_TREE](state) {
    state.filesTree = [];
    state.loadingFilesTree = true;
  },
  [actionTypes.LOAD_FILES_TREE_COMPLETED](state, payload) {
    const { data } = payload;
    state.filesTree = [...data];
    state.loadingFilesTree = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
