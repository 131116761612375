import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!!_vm.getActiveDialog)?_c(VDialog,{attrs:{"width":_vm.getActiveDialog.width ? _vm.getActiveDialog.width : 330,"fullscreen":_vm.isFullscreen,"persistent":_vm.getActiveDialog.persistent},model:{value:(_vm.getActiveDialog.show),callback:function ($$v) {_vm.$set(_vm.getActiveDialog, "show", $$v)},expression:"getActiveDialog.show"}},[_c(VCard,[_c('toolbar',{attrs:{"back-button":_vm.getActiveDialog.backButton,"color":"secondary","height":56},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.getActiveDialog.translate ? _vm.$t(_vm.getActiveDialog.title) : _vm.getActiveDialog.title)+" ")]},proxy:true}],null,false,835406037)},[(_vm.isFullscreen)?_c('div',_vm._l((_vm.getActiveDialog.actions),function(action,i){return _c(VBtn,{key:i,staticClass:"ml-1",attrs:{"depressed":"","color":("" + (action.color))},on:{"click":function($event){return _vm.dialogButtonAction(action, _vm.getActiveDialog.params)}}},[_vm._v(" "+_vm._s(action.text)+" ")])}),1):_vm._e(),_c(VDivider,{style:(("border-width: 1px;\n        background: " + _vm.getBorderColor + ";\n        position: absolute;\n        bottom: 0px;\n        left: 0;\n        right: 0;"))})],1),_c(VCardText,{class:!_vm.getActiveDialog.noGutter ? 'pa-3' : 'pa-0'},[_vm._v(" "+_vm._s(_vm.getActiveDialog.translate ? _vm.$t(_vm.getActiveDialog.text) : _vm.getActiveDialog.text)+" "),(_vm.getActiveDialog.component)?_c(_vm.getActiveDialog.component,{ref:"dialogComponent",tag:"component",model:{value:(_vm.getActiveDialog.params),callback:function ($$v) {_vm.$set(_vm.getActiveDialog, "params", $$v)},expression:"getActiveDialog.params"}}):_vm._e()],1),_c(VDivider),(!_vm.isFullscreen)?_c(VCardActions,[(
          _vm.getActiveDialog.params &&
          _vm.getActiveDialog.params.validated &&
          !_vm.getActiveDialog.params.valid
        )?_c(VAlert,{staticClass:"body-2",attrs:{"border":"left","colored-border":"","type":"error","dense":""}},[_vm._v(" Incorrect or missing, please check validation messages. ")]):_vm._e(),_c(VSpacer),_vm._l((_vm.getActiveDialog.actions),function(action){return _c(VBtn,{key:action.text,attrs:{"color":("" + (action.color)),"text":""},on:{"click":function($event){return _vm.dialogButtonAction(action, _vm.getActiveDialog.params)}}},[_vm._v(" "+_vm._s(action.text)+" ")])})],2):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }