import * as actionTypes from "../actions/type.ui";
import { Plugins } from "@capacitor/core";
import moment from "moment";

const { Device, StatusBar } = Plugins;

const state = {
  language: "en",
  theme: "default",
  themes: [
    {
      name: "default",
      light: {
        primary: "#003459",
        secondary: "#00ff9f",
        darkSecondary: "#5EFF00",
        accent: "#607d8b",
        error: "#ff4721",
        warning: "#ffdf2d",
        info: "#40bcd4",
        success: "#5EFF00",
      },
    },
    {
      name: "admin",
      light: {
        primary: "#08364b",
        secondary: "#fcfd5d",
        darkSecondary: "#dede03",
        accent: "#607d8b",
        error: "#e91e63",
        warning: "#ffc107",
        info: "#00bcd4",
        success: "#4caf50",
      },
    },
    {
      name: "driver",
      light: {
        primary: "#16236e",
        secondary: "#54c8fd",
        darkSecondary: "#039ee9",
        accent: "#607d8b",
        error: "#e91e63",
        warning: "#ffc107",
        info: "#54c8fd",
        success: "#4caf50"
      },
    },
  ],
  activeDialog: null,
  activeNotification: { show: false, timeout: 4000, alert: false },
  menuBadges: { offers: [], orders: [] },
  loading: { state: false, message: "" },
  offline: false,
  isMobile: false,
  homePage: "store",
  reactivityParams: [],
};

const getters = {
  [actionTypes.GET_HOME_PAGE](state) {
    return state.homePage;
  },
  [actionTypes.GET_COLOR]: (state) => (name) => {
    const current = state.themes.find(f => f.name === state.theme);
    return current.light[name];
  },
  [actionTypes.GET_LANGUAGE](state) {
    return state.language;
  },
  [actionTypes.GET_LOADING_STATE](state) {
    return state.loading.state;
  },
  [actionTypes.GET_LOADING_MESSAGE](state) {
    return state.loading.message;
  },
  [actionTypes.GET_ACTIVE_DIALOG](state) {
    return state.activeDialog;
  },
  [actionTypes.GET_ACTIVE_NOTIFICATION](state) {
    return state.activeNotification;
  },
  [actionTypes.GET_MENU_BADGES]: (state) => (resource) => {
    return state.menuBadges[resource];
  },
  [actionTypes.GET_MENU_BADGES_COUNTER]: (state) => (resource) => {
    return state.menuBadges[resource] ? state.menuBadges[resource].length : 0;
  },
  [actionTypes.GET_OFFLINE]: (state) => (resource) => {
    return state.offline;
  },
  [actionTypes.IS_MOBILE](state) {
    return state.isMobile;
  },
  [actionTypes.GET_REACTIVITY_PARAMS](state) {
    return state.reactivityParams;
  },
};

const actions = {
  [actionTypes.SET_HOME_PAGE]({ commit }, page) {
    commit(actionTypes.SET_HOME_PAGE, page);
  },
  [actionTypes.SET_LOADING_STATE]({ commit }, message) {
    commit(actionTypes.SET_LOADING_STATE, message);
  },
  [actionTypes.CLEAR_LOADING_STATE]({ commit }) {
    commit(actionTypes.CLEAR_LOADING_STATE);
  },
  [actionTypes.SET_LANGUAGE]({ commit }, lang) {
    commit(actionTypes.SET_LANGUAGE, lang);
  },
  [actionTypes.SET_THEME]({ commit }, theme) {
    commit(actionTypes.SET_THEME, theme);
  },
  [actionTypes.SHOW_FEEDBACK]({ commit }, notification) {
    commit(actionTypes.SHOW_FEEDBACK, notification);
  },
  [actionTypes.DISMISS_FEEDBACK]({ commit }) {
    commit(actionTypes.DISMISS_FEEDBACK);
  },
  [actionTypes.SHOW_DIALOG]({ commit }, dialog) {
    commit(actionTypes.SHOW_DIALOG, dialog);
  },
  [actionTypes.REFRESH_DIALOG]({ commit }) {
    commit(actionTypes.REFRESH_DIALOG);
  },
  [actionTypes.CLOSE_DIALOG]({ commit }) {
    commit(actionTypes.CLOSE_DIALOG);
  },
  [actionTypes.ADD_TO_MENU_BADGE]({ commit }, params) {
    const { resource, keys } = params;
    commit(actionTypes.ADD_TO_MENU_BADGE, { resource, keys });
  },
  [actionTypes.CLEAR_MENU_BADGE]({ commit }, params) {
    const { resource } = params;
    commit(actionTypes.CLEAR_MENU_BADGE, { resource });
  },
  [actionTypes.SET_OFFLINE]({ commit }, offline) {
    commit(actionTypes.SET_OFFLINE, offline);
  },
  [actionTypes.SET_IS_MOBILE]({ commit }) {
    Device.getInfo().then((value) => commit(actionTypes.SET_IS_MOBILE, value));
  },
  [actionTypes.SET_REACTIVITY_PARAMS]({ commit }, params) {
    commit(actionTypes.SET_REACTIVITY_PARAMS, params);
  },
  [actionTypes.CLEAR_REACTIVITY_PARAMS]({ commit }) {
    commit(actionTypes.CLEAR_REACTIVITY_PARAMS);
  },
};

const mutations = {
  [actionTypes.SET_HOME_PAGE](state, payload) {
    state.homePage = payload;
  },
  [actionTypes.SET_LOADING_STATE](state, payload) {
    state.loading = { state: true, message: payload };
  },
  [actionTypes.CLEAR_LOADING_STATE](state) {
    state.loading = { state: false, message: "" };
  },
  [actionTypes.SET_LANGUAGE](state, payload) {
    state.lang = payload;
  },
  [actionTypes.SET_THEME](state, payload) {
    state.theme = payload;
  },
  [actionTypes.SHOW_FEEDBACK](state, payload) {
    state.activeNotification = { timeout: 4000, ...payload, show: true };
  },
  [actionTypes.DISMISS_FEEDBACK](state) {
    state.activeNotification = { show: false, timeout: 4000, alert: false };
  },
  [actionTypes.SHOW_DIALOG](state, payload) {
    payload.show = true;
    state.activeDialog = payload;
  },
  [actionTypes.REFRESH_DIALOG](state) {
    state.activeDialog = { ...state.activeDialog };
  },
  [actionTypes.CLOSE_DIALOG](state) {
    state.activeDialog = null;
  },
  [actionTypes.ADD_TO_MENU_BADGE](state, payload) {
    if (!payload.resource) {
      return;
    }

    state.menuBadges = {
      ...state.menuBadges,
      [payload.resource]: [
        ...state.menuBadges[payload.resource],
        ...payload.keys,
      ],
    };
  },
  [actionTypes.CLEAR_MENU_BADGE](state, payload) {
    if (!payload.resource) {
      return;
    }

    state.menuBadges = {
      ...state.menuBadges,
      [payload.resource]: [],
    };
  },
  [actionTypes.SET_OFFLINE](state, payload) {
    state.offline = payload;
  },
  [actionTypes.SET_IS_MOBILE](state, payload) {
    state.isMobile = payload;
    if (payload) {
      StatusBar.setStyle({
        color: "#9eeacb",
      });
    }
  },
  [actionTypes.SET_REACTIVITY_PARAMS](state, payload) {
    const since = moment().format('yyyy-MM-DDTHH:mm:ss');
    if (payload instanceof Array) {
      if(state.reactivityParams.length > 0) {
        const added = [];
        const updated = state.reactivityParams.map(m => {
          const p = payload.find(f => f.resource === m.resource && f.scope === m.scope && f.key === m.key)
          if(p) {
            return {...m, since};
          }
          added.push(p)
          return m;
        });
        state.reactivityParams = [...updated, ...added];
      } else {
        state.reactivityParams = payload.map(m => ({...m, since}));
      }
    } else if(payload) {
      const exist = state.reactivityParams.find(f => f.resource === m.resource && f.scope === m.scope && f.key === m.key)
      if (exist) {
        state.reactivityParams = state.reactivityParams.map(m => {
          if (m.resource == payload.resource && m.scope === payload.scope && m.key === payload.key) {
            return { ...m, since };
          }
          return m;
        })
      } else {
        state.reactivityParams = [...state.reactivityParams, { ...payload, since }]
      }
    }
  },
  [actionTypes.CLEAR_REACTIVITY_PARAMS](state, payload) {
    state.reactivityParams = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
