// cart
export const ADD_TO_CART = "addToCart";
export const REMOVE_FROM_CART = "removeFromCart";
export const DELETE_FROM_CART = "deleteFromCart";
export const CLEAR_CART = "clearCart";
export const CHECKOUT_CART = "checkoutCart";
export const CHECKOUT_CART_COMPLETED = "checkoutCartCompleted";
export const PRECHECK_CART = "preCheckCart";
export const PRECHECK_CART_COMPLETED = "preCheckCartCompleted";

// only actions
export const CHECK_STOCK = "checkStock";

// getters
export const GET_CART_STORE = "getCartStore";
export const GET_CART_CURRENCY = "getCartCurrency";
export const GET_CART_STORE_ID = "getCartStoreId";
export const GET_CART_ITEMS = "getCartItems";
export const GET_CART_ITEMS_QUANTITY = "getCartItemsQuantity";
export const GET_CART_ITEM = "getCartItem";
export const GET_CART_ITEM_QUANTITY = "getCartItemQuantity";
export const GET_CART_TOTAL_PRICE = "getCartTotalPrice";
export const IS_FREE_DELIVERY = "isFreeDelivery";
export const GET_CART_GRAND_TOTAL = "getCartGrandTotal";
export const IS_IN_PROGRESS = "isInProgress";
export const IS_CART_LOCKED = "isCartLocked";
export const GET_ACTIVE_CART_STORE_ID = "getActiveCartStoreId";
export const IS_AOOS_LOCKED = "isAddressOutOfServiceLocked";
