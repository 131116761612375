import { apiService } from "@/services";
import * as actionTypes from "../actions/type.types";
import { SHOW_FEEDBACK } from "../actions/type.ui";

const API_PATH = "types";

// initial state
const state = {
  types: [],
  typeGroups: [
    {
      // name: "settings.dataTypes.unitTypes",
      locale: "settings.dataTypes.unitTypes",
      route: "unit-types",
      permission: "data.types:unit.types",
      type: "UNIT_TYPE",
    },
    {
      // name: "settings.dataTypes.tags",
      locale: "settings.dataTypes.tags",
      route: "tags",
      permission: "data.types:tags",
      type: "TAG",
    },
  ],
  isLoading: false,
};

// getters
const getters = {
  [actionTypes.GET_TYPE_GROUPS]: (state) => () => {
    return state.typeGroups;
  },
  [actionTypes.GET_TYPE_GROUP]: (state) => (route) => {
    return state.typeGroups.find((f) => f.route === route);
  },
  [actionTypes.GET_TYPES]: (state) => () => {
    return state.types;
  },
  [actionTypes.GET_TYPES_BY_GROUP]: (state) => (group) => {
    return state.types.filter((f) => f.typeGroup === group);
  },
  [actionTypes.GET_TYPE_BY_GROUP_AND_ID]: (state) => (group, id) => {
    return state.types.find((f) => f.typeGroup === group && f.id === id);
  },
  [actionTypes.GET_TYPES_BY_GROUP_AND_IDS]: (state) => (group, ids) => {
    return state.types.filter((f) => f.typeGroup === group && ids.includes(f.id));
  },
  [actionTypes.GET_TYPE_BY_GROUP_AND_NAME]: (state) => (group, name) => {
    return state.types.find((f) => f.typeGroup === group && f.name === name);
  },
  [actionTypes.GET_TYPE_NAME]: (state, getter, rootState) => (id, group) => {
    const propName = rootState.ui.language === "ar" ? "nameAr" : "name";
    const type = state.types.find((f) => f.typeGroup === group && f.id === id);
    if (type) {
      return type[propName];
    }
    return null;
  },
  [actionTypes.GET_TYPE_NAME_EN]: (state) => (id, group) => {
    const type = state.types.find((f) => f.typeGroup === group && f.id === id);
    if (type) {
      return type.name;
    }
    return null;
  },
};

// actions
const actions = {
  [actionTypes.RECACHE_TYPES]({ commit, rootState }) {
    commit(actionTypes.LOAD_TYPES);
    return new Promise((resolve, reject) => {
      apiService.query(`${API_PATH}/recache`)
        .then(({ data }) => {
          const sortBy = rootState.ui.language === "ar" ? "nameAr" : "name";
          commit(actionTypes.LOAD_TYPES_COMPLETED, { payload: data, sortBy });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  async [actionTypes.LOAD_TYPES]({ commit, state, rootState }, reload) {
    // return apiActions.load(API_PATH, null, commit, actionTypes.LOAD_TYPES_COMPLETED, null, {
    //   onSuccess: (data) => {

    //   }
    // });

    if (reload || state.types.length === 0) {
      commit(actionTypes.LOAD_TYPES);
      return new Promise((resolve, reject) => {
        apiService.query(API_PATH)
          .then(({ data }) => {
            const sortBy = rootState.ui.language === "ar" ? "nameAr" : "name";
            commit(actionTypes.LOAD_TYPES_COMPLETED, { payload: data, sortBy });
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    }
  },
  async [actionTypes.LOAD_TYPES_BY_GROUP]({ commit }, group) {
    commit(actionTypes.LOAD_TYPES);
    return new Promise((resolve, reject) => {
      apiService.get(API_PATH, group)
        .then(({ data }) => {
          commit(actionTypes.LOAD_TYPES_BY_GROUP_COMPLETED, {
            group,
            items: data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
  async [actionTypes.SAVE_TYPE]({ commit, dispatch }, params) {
    if (!params) {
      return;
    }

    return apiService.patch(API_PATH, params)
      .then(({ data }) => {
        commit(actionTypes.SAVE_TYPE, { payload: data });
        dispatch(
          `ui/${SHOW_FEEDBACK}`,
          {
            text: "messages.updateDone",
            color: "success",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch(
          `ui/${SHOW_FEEDBACK}`,
          {
            text: "messages.operationError",
            color: "grey",
          },
          { root: true }
        );
        throw new Error(error);
      });
  },
};

// mutations
const mutations = {
  [actionTypes.LOAD_TYPES](state) {
    state.isLoading = true;
  },
  [actionTypes.LOAD_TYPES_COMPLETED](state, { payload, sortBy }) {
    state.types = payload
      ? payload.sort((x, y) => {
          let a = x[sortBy] ? x[sortBy].toUpperCase() : -1,
            b = y[sortBy] ? y[sortBy].toUpperCase() : -1;
          return a == b && a > -1 ? 0 : a > b ? 1 : -1;
        })
      : payload;
    state.isLoading = false;
  },
  [actionTypes.LOAD_TYPES_BY_GROUP_COMPLETED](state, payload) {
    if (state.types && state.types.length > 0) {
      state.types = [
        ...state.types.filter((f) => f.typeGroup !== payload.group),
        ...payload.items,
      ];
    } else {
      state.types = payload.items;
    }
    state.isLoading = false;
  },
  [actionTypes.SAVE_TYPE](state, payload) {
    const { type, items } = payload;
    if (!type && !items) {
      return;
    }

    state.types = state.types.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
